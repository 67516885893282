<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ background.id ? 'Edit' : 'New' }} professional background
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to professional background"
              @click="$router.push({name: 'researcher.professional-background.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
              @click="$store.dispatch('modals/toggleRequestModal', 'ProfessionalBackground')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="background" />
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Position</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['position.name'] }}</label>
                        <input
                          v-model="background.title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row row-md-2">
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['position.begin_date'] }}</label>
                        <date-picker
                          v-model="background.start_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-1 form-group">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['position.end_date'] }}</label>
                        <date-picker
                          v-model="background.end_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div
                    v-if="hasAdminPermission('update professional backgrounds')"
                    class="row row-md-2"
                  >
                    <div class="col">
                      <div class="form-check form-check-success form-switch d-flex align-items-center">
                        <input
                          id=""
                          v-model="background.current_position"
                          type="checkbox"
                          :value="true"
                          class="form-check-input"
                          name="currentPosition"
                        >
                        <label
                          class="form-check-label"
                          for=""
                        ><strong class="ms-1">{{ labels['position.current_position'] }} </strong></label>
                      </div>
                      <p><small class="help-text"><em>{{ labels['position.current_position_description'] }}</em></small></p>
                    </div>
                    <div class="col">
                      <div class="form-check form-check-success form-switch d-flex align-items-center">
                        <input
                          id=""
                          v-model="background.current_position_2"
                          type="checkbox"
                          :value="true"
                          class="form-check-input"
                          name="currentPosition"
                        >
                        <label
                          class="form-check-label"
                          for=""
                        ><strong class="ms-1">{{ labels['position.current_position_2'] }} </strong></label>
                      </div>
                    </div>
                  </div>
                  <hr v-if="hasAdminPermission('update professional backgrounds')">

                  <div class="row">
                    <div class="col">
                      <div class="form-check form-check-success form-switch d-flex align-items-center">
                        <input
                          id=""
                          v-model="background.published_web"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="form-check-label"
                          for=""
                        ><small class="ms-1">{{ labels['position.active'] }} </small></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <InstitutionAndDepartment
              v-if="!loading"
              :item="background"
              :show-country="true"
              :show-address="((background.department_name && background.institution_name) || (background.institution.id && background.department.id))"
              :required-fields="true"
            />
          </div>
          <div class="col-12">
            <AttachedForm
              :type="'ProfessionalBackground'"
              :model-id="backgroundId"
            />
          </div>
        </div>
        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import InstitutionAndDepartment from '../../partials/components/InstitutionAndDepartment.vue'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    SearchResearcher,
    DatePicker,
    InstitutionAndDepartment,
    AttachedForm,
  },
  data() {
    return {
      backgroundId: this.$route.params.id,
      loading: true,
      manualInstitution: false,
    }
  },
  computed: {
    ...mapGetters({
      background: 'backgrounds/background',
      institutions: 'institutions/institutions',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'my_profile.professional_backgrounds')
    if (this.backgroundId) {
      await this.$store.dispatch('backgrounds/fetchBackground', this.backgroundId)
    } else {
      await this.$store.dispatch('backgrounds/cleanBackground')
    }
    this.loading = false
  },
  methods: {
    async save() {
      this.loading = true
      this.background.attached_form = this.attached
      try {
        if (this.backgroundId) {
          await this.$store.dispatch('backgrounds/update', { backgroundId: this.background.id, data: this.background })
        } else {
          await this.$store.dispatch('backgrounds/create', this.background)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.background.id) {
        Vue.swal('Professional background updated successfully!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.professional-background.index' })
        })
      } else {
        // Vue.swal
      }

      this.loading = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
